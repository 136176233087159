import { Dashboard } from "@modules";
import { useAuth } from "@contexts";
import Fade from "react-reveal/Fade";

export default function Page() {
  const { currentUser } = useAuth();

  return (
    <div className="container content-max">
      <h1 className="text-3xl m-0">Добро пожаловать, {currentUser?.name}</h1>
      <h2 className="mt-2 mb-8 text-base">Ознакомьтесь с последней сводкой</h2>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Dashboard.Widgets.Rates />
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Dashboard.Widgets.Scheduled />
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Dashboard.Widgets.Published />
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Dashboard.Widgets.People />
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Dashboard.Widgets.Projects />
          </div>
          <div className="col-span-12">
            <Dashboard.Widgets.ScheduledList />
          </div>
          <div className="col-span-12">
            <Dashboard.Widgets.Popular />
          </div>
          <div className="col-span-12">
            <Dashboard.Widgets.InReview />
          </div>
          <div className="col-span-12">
            <Dashboard.Widgets.Recent />
          </div>
        </div>
      </Fade>
    </div>
  );
}
