import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AuthContextProvider from "@contexts";
import config from "@config";
import ruRU from "antd/lib/locale/ru_RU";
import CacheBuster from "react-cache-buster";
import pgk from "../package.json";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import { Loading } from "@components";
import "dayjs/locale/ru";
import "antd/dist/reset.css";
import "@styles/global.scss";

Sentry.init({
  dsn: "https://3cc4de93aa56d54e4df70180aec3ca3e@o1343877.ingest.us.sentry.io/4509004742918144",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
dayjs.locale("ru");

root.render(
  <CacheBuster
    currentVersion={pgk.version}
    isEnabled
    isVerboseMode={false}
    loadingComponent={<Loading />}
  >
    <ConfigProvider {...config.antd} locale={ruRU}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </ConfigProvider>
  </CacheBuster>
);
