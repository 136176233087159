// import Paragraph from "@editorjs/paragraph";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import List from "@editorjs/list";
import Raw from "@editorjs/raw";
import Hyperlink from "editorjs-hyperlink";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import HTMLExecutable from "./html_executable";
import Image from "./image";
import Carousel from "./carousel";
import Embed from "@editorjs/embed";
import EmbedArticle from "./embedarticle";
import Table from "@editorjs/table";
import Delimiter from "@editorjs/delimiter";
import SideImage from "./sideimage";
import Paragraph from "./paragraph";
import * as StaticAPI from "@api/static";

export const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    tunes: ["alignment"],
  },
  hyperlink: {
    class: Hyperlink,
    config: {
      shortcut: "CMD+L",
      target: "_blank",
      rel: "",
      availableTargets: ["_blank", "_self"],
      availableRels: [
        "author",
        "noreferrer",
        "noopener",
        "nofollow",
        "noopener noreferrer",
        "nofollow noopener",
        "noreferrer nofollow",
        "nofollow ugc",
        "sponsored",
        "ugc",
        "nofollow sponsored",
      ],
      validate: false,
    },
  },
  heading: {
    class: Header,
    inlineToolbar: true,
    tunes: ["alignment"],
  },
  image: {
    class: Image,
    inlineToolbar: true,
    config: {
      captionPlaceholder: "Текст изображения",
      uploader: {
        async uploadByFile(file) {
          const res = await StaticAPI.uploadImage({ file });

          if (!res?.data?.path || res?.error) {
            return { success: 0 };
          }

          return {
            success: 1,
            file: {
              url: res.data.imgproxyUrl,
              path: res.data.path,
              mime: res.data.mime,
              fullPath: res.data.imgPath,
            },
          };
        },
      },
    },
  },
  sideimage: {
    class: SideImage,
    inlineToolbar: true,
    config: {
      captionPlaceholder: "Текст изображения",
      uploader: {
        async uploadByFile(file) {
          const res = await StaticAPI.uploadImage({ file });

          if (!res?.data?.path || res?.error) {
            return { success: 0 };
          }

          return {
            success: 1,
            file: {
              url: res.data.imgproxyUrl,
              path: res.data.path,
              mime: res.data.mime,
              fullPath: res.data.imgPath,
            },
          };
        },
      },
    },
  },
  carousel: {
    class: Carousel,
    inlineToolbar: true,
    config: {
      captionPlaceholder: "Текст изображения",
      uploader: {
        async uploadByFile(file) {
          const res = await StaticAPI.uploadImage({ file });

          if (!res?.data?.path || res?.error) {
            return { success: 0 };
          }

          return {
            success: 1,
            file: {
              url: res.data.imgproxyUrl,
              path: res.data.path,
              mime: res.data.mime,
              fullPath: res.data.imgPath,
            },
          };
        },
      },
    },
  },
  list: {
    class: List,
    inlineToolbar: true,
    tunes: ["alignment"],
  },
  quote: {
    class: Quote,
    inlineToolbar: true,
    config: {
      quotePlaceholder: "Введите цитату",
      captionPlaceholder: "Введите подпись / автора",
    },
  },
  embedarticle: {
    class: EmbedArticle,
    inlineToolbar: true,
  },
  html: {
    class: Raw,
    inlineToolbar: true,
    config: {
      placeholder: "Введите HTML-код",
    },
  },
  html_executable: {
    class: HTMLExecutable,
    inlineToolbar: true,
    config: {
      placeholder: "Введите HTML-код",
    },
  },
  table: {
    class: Table,
    inlineToolbar: true,
    config: {
      rows: 2,
      cols: 3,
    },
  },
  embed: {
    class: Embed,
  },
  delimiter: {
    class: Delimiter,
  },
  alignment: {
    class: AlignmentTuneTool,
    config: {
      services: {
        facebook: true,
        instagram: true,
        youtube: true,
        twitter: true,
        vimeo: true,
        imgur: true,
        "yandex-music-track": true,
        "yandex-music-album": true,
        "yandex-music-playlist": true,
      },
    },
  },
};
